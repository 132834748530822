import { Observable } from 'rxjs';
import { RcgCalendarEventException, RcgEvent, RcgView } from '.';

export abstract class ICalendarService {
  abstract getEvents(view: RcgView, resourceGroupId: number, start: Date, end: Date): Observable<RcgEvent[]>;
  abstract getEventExceptions(eventId: number): Observable<RcgCalendarEventException[]>;
  abstract addEvent(view: RcgView, record: Record<string, unknown>, isDeleted?: boolean): Promise<void>;
  abstract updateEvent(view: RcgView, record: Record<string, unknown>, isDeleted?: boolean): Promise<void>;
  abstract deleteEvent(record: Record<string, unknown>): Promise<void>;
}
