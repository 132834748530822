import { RcgTenant, RcgUser } from '@rcg/auth';
import { DynamicVariable, parseVariable as parseDynamicVariable } from './variable.model';

export interface DynamicConditionEq {
  _eq: DynamicVariable[];
}

export interface DynamicConditionNeq {
  _neq: DynamicVariable[];
}

export interface DynamicConditionLt {
  _lt: DynamicVariable[];
}

export interface DynamicConditionGt {
  _gt: DynamicVariable[];
}

export interface DynamicConditionLte {
  _lte: DynamicVariable[];
}

export interface DynamicConditionGte {
  _gte: DynamicVariable[];
}

export interface DynamicConditionIn {
  _in: DynamicVariable[];
}

export interface DynamicConditionNotIn {
  _not_in: DynamicVariable[];
}

export interface DynamicConditionAnd {
  _and: DynamicCondition[];
}

export interface DynamicConditionOr {
  _or: DynamicCondition[];
}

export interface DynamicConditionContains {
  _contains: DynamicCondition[];
}

export interface DynamicConditionNotContains {
  _notcontains: DynamicCondition[];
}
export type DynamicCondition =
  | DynamicConditionEq
  | DynamicConditionNeq
  | DynamicConditionLt
  | DynamicConditionGt
  | DynamicConditionLte
  | DynamicConditionGte
  | DynamicConditionIn
  | DynamicConditionNotIn
  | DynamicConditionContains
  | DynamicConditionNotContains
  | DynamicConditionAnd
  | DynamicConditionOr;

export function evaluateCondition(
  condition: DynamicCondition,
  props: { data: Record<string, unknown>; user: RcgUser | null; tenant: RcgTenant | null },
): boolean {
  if (!condition) return true;

  if ('_eq' in condition) {
    return condition._eq.every((v) => parseDynamicVariable(v, props) === parseDynamicVariable(condition._eq[0], props));
  }

  if ('_neq' in condition) {
    return condition._neq.every((v) => parseDynamicVariable(v, props) !== parseDynamicVariable(condition._neq[0], props));
  }

  if ('_lt' in condition) {
    const firstValue = parseDynamicVariable(condition._lt[0], props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return condition._lt.slice(1).every((v) => (firstValue as any) < (parseDynamicVariable(v, props) as any));
  }

  if ('_gt' in condition) {
    const firstValue = parseDynamicVariable(condition._gt[0], props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return condition._gt.slice(1).every((v) => (firstValue as any) > (parseDynamicVariable(v, props) as any));
  }

  if ('_lte' in condition) {
    const firstValue = parseDynamicVariable(condition._lte[0], props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return condition._lte.slice(1).every((v) => (firstValue as any) <= (parseDynamicVariable(v, props) as any));
  }

  if ('_gte' in condition) {
    const firstValue = parseDynamicVariable(condition._gte[0], props);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return condition._gte.slice(1).every((v) => (firstValue as any) >= (parseDynamicVariable(v, props) as any));
  }

  if ('_in' in condition) {
    const firstValue = parseDynamicVariable(condition._in[0], props);
    return condition._in.slice(1).some((v) => firstValue === parseDynamicVariable(v, props));
  }

  if ('_not_in' in condition) {
    const firstValue = parseDynamicVariable(condition._not_in[0], props);
    return condition._not_in.slice(1).every((v) => firstValue !== parseDynamicVariable(v, props));
  }

  if ('_contains' in condition) {
    const val = parseDynamicVariable(condition._contains[0], props) as Record<string, unknown>[] | undefined;
    return condition._contains.slice(1).some((v) => {
      return val?.some((va) => {
        const val1 = parseDynamicVariable(v, props) as Record<string, unknown>[] | undefined;
        return val1?.some((qa) => qa === va);
      });
    });
  }

  if ('_notcontains' in condition) {
    const val = parseDynamicVariable(condition._notcontains[0], props) as Record<string, unknown>[] | undefined;
    return !condition._notcontains.slice(1).some((v) => {
      return val?.some((va) => {
        const val1 = parseDynamicVariable(v, props) as Record<string, unknown>[] | undefined;
        return val1?.some((qa) => qa === va);
      });
    });
  }

  if ('_and' in condition) {
    return condition._and.every((c) => evaluateCondition(c, props));
  }

  if ('_or' in condition) {
    return condition._or.some((c) => evaluateCondition(c, props));
  }

  console.error('Unknown condition type', condition);
  return true;
}
