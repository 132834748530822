import { gql } from '@apollo/client/core';

export const addEventMutation = gql`
  mutation AddEventMutation(
    $viewId: bigint!
    $rruleset: rruleset!
    $title: String
    $description: String
    $comment: String
    $exception_for_id: bigint
    $isDeleted: Boolean = false
    $assignments: jsonb!
    $extra: jsonb
    $report: String
    $form_id: Int! = 892
  ) {
    sqlFunctionMutation(
      functionName: "calendar.add_event"
      parameters: {
        view_id: $viewId
        rruleset: $rruleset
        title: $title
        description: $description
        comment: $comment
        exception_for_id: $exception_for_id
        is_deleted: $isDeleted
        assignments: $assignments
        extra: $extra
        report: $report
        form_id: $form_id
      }
    ) {
      __typename
    }
  }
`;
