import { gql } from 'apollo-angular';

export const getDirectCallByPkQuery = gql`
  query getDirectCallByPk($id: bigint!) {
    data: telephony_o365_dr_calls_by_pk(id: $id) {
      id
      created_at
      updated_at
      call_id
      call_end_subreason
      call_type
      callee_number
      caller_number
      correlation_id
      duration
      end_date
      failure_date
      final_sipcode_phrase
      final_sipcode
      invite_date
      media_bypass_enabled
      media_path_location
      signaling_location
      start_date
      successful_call
      trunk_fully_qualified_domain_name
      user_display_name
      user_id
      user_principalname
      tenant_id
      organization_id
      agent_id
      agent_name
      contact_id
      contact_name
    }
  }
`;
