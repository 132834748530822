import { gql } from '@apollo/client/core';
import { GraphqlClientService } from '@rcg/graphql';
import { isNonNullable } from '@rcg/utils';
import jsonpath from 'jsonpath';
import { combineLatest, debounceTime, map, Observable, of, switchMap } from 'rxjs';
import {
  mergeAcls,
  parseVariable,
  RcgCalendarResource,
  RcgCalendarResourceGroup,
  RcgCalendarView,
  RcgResource,
  RcgResourceGroup,
  RcgView,
} from '../models';

export class RcgCalendarDataMappingUtil {
  constructor(
    private readonly locale$: Observable<string>,
    private readonly gqlClient?: GraphqlClientService,
    private readonly getExternalResourceExtraProps?: () => Record<string, unknown> | Promise<Record<string, unknown>>,
  ) {}

  private mapResource$(resource: RcgCalendarResource | null, resources: RcgCalendarResource[]): Observable<RcgResource | null> {
    if (!resource) return of(null);

    const staticData = {
      color: resource.color.replace(/^\\x/, '#'),
      //! Order is important for correct display of wide parent assigned events
      children: (resource.children ?? [])
        .map((c) => ({ i: resources.findIndex((r) => r.id === c.id), c }))
        .sort((a, b) => a.i - b.i)
        .map(({ c }) => c.id),
      isParent: !resource.parent_id,
      expanded: true,
    };

    return this.locale$.pipe(
      map(
        (locale) =>
          ({
            ...resource,
            ...staticData,
            name: resource.name_ml?.[locale] ?? resource.name,
            name_ml: undefined,
          } as RcgResource),
      ),
    );
  }

  private mapResourceGroup$(g: RcgCalendarResourceGroup): Observable<RcgResourceGroup> {
    const localized$ = this.locale$.pipe(
      map((locale) => ({
        name: g.name_ml?.[locale] ?? g.name,
        name_ml: undefined,
      })),
    );

    if (!g.ext_config) {
      const rgrResources = g.resource_group_resources?.map((rgr) => rgr.resource) ?? [];
      const resources$ = of(rgrResources).pipe(
        switchMap((rgrResources) =>
          rgrResources.length
            ? combineLatest(rgrResources.map((r) => this.mapResource$(r, rgrResources))).pipe(
                debounceTime(10),
                map((resources) => resources.filter(isNonNullable)),
              )
            : of([]),
        ),
      );

      const resourceById = (id: unknown) => resources$.pipe(map((resources) => resources.find((r) => r.id === id)));
      const allResources = () => resources$;
      const searchResources = (query: string, limit: number, offset: number) => {
        const lcQuery = query.toLowerCase();

        return resources$.pipe(
          map((resources) => {
            const filtered = resources
              .filter((r) => (r.active ?? true) && r.name.toLowerCase().includes(lcQuery))
              .slice(offset, offset + limit);

            const parentIds = new Set(filtered.map((r) => r.parent_id));
            const parents = resources.filter((r) => parentIds.has(r.id));

            return [...new Set([...parents, ...filtered])];
          }),
        );
      };

      return localized$.pipe(
        map((l) => ({
          ...g,
          ...l,
          resourceById,
          allResources,
          searchResources,
        })),
      );
    }

    if (g.ext_config.type !== 'graphql' || !this.gqlClient) {
      console.error(
        g.ext_config.type !== 'graphql' ? 'Unsupported resource group ext config:' : 'Cannot use GraphQL ext config without GQL client',
        g.ext_config,
      );

      return localized$.pipe(
        map((l) => ({
          ...g,
          ...l,
          resourceById: () => of(undefined),
          allResources: () => of([]),
          searchResources: () => of([]),
        })),
      );
    }

    const resourceById = (id: unknown) => {
      if (id === null || id === undefined) return of(undefined);

      const props = {
        id,
        group: g,
      };

      const variables = Object.fromEntries(
        Object.entries(g.ext_config!.queryByPk.variables ?? {}).map(([key, value]) => [key, parseVariable(value, props)]),
      );

      return this.gqlClient!.query({
        query: gql(g.ext_config!.queryByPk.query),
        variables,
      }).pipe(
        map((data) => jsonpath.value(data, g.ext_config!.queryByPk.dataRef) as RcgResource | null | undefined),
        map((data) => {
          if (!data) return undefined;

          const props = {
            group: g,
            resourceId: id,
            data,
            ...this.getExternalResourceExtraProps?.(),
          };

          for (const [path, variable] of Object.entries(g.ext_config!.queryByPk.dataVariables ?? {})) {
            jsonpath.value(data, path, parseVariable(variable, props));
          }

          return data;
        }),
      );
    };

    const allResources = () => {
      const props = {
        group: g,
        ...this.getExternalResourceExtraProps?.(),
      };

      const variables = Object.fromEntries(
        Object.entries(g.ext_config!.queryAll.variables ?? {}).map(([key, value]) => [key, parseVariable(value, props)]),
      );

      return this.gqlClient!.query({
        query: gql(g.ext_config!.queryAll.query),
        variables,
      }).pipe(
        map((data) => jsonpath.value(data, g.ext_config!.queryAll.dataRef) as RcgResource[] | null | undefined),
        map((data) => {
          if (!data) return [];

          data.forEach((resource) => {
            const resourceProps = {
              group: g,
              resourceId: resource.id,
              data: resource,
            };

            for (const [path, variable] of Object.entries(g.ext_config!.queryAll.dataVariables ?? {})) {
              jsonpath.value(resource, path, parseVariable(variable, resourceProps));
            }
          });

          return data;
        }),
      );
    };

    const searchResources = (search: string, limit: number, offset: number) => {
      const props = {
        group: g,
        search,
        limit,
        offset,
        ...this.getExternalResourceExtraProps?.(),
      };

      const variables = Object.fromEntries(
        Object.entries(g.ext_config!.querySearch.variables ?? {}).map(([key, value]) => [key, parseVariable(value, props)]),
      );

      return this.gqlClient!.query({
        query: gql(g.ext_config!.querySearch.query),
        variables,
      }).pipe(
        map((data) => jsonpath.value(data, g.ext_config!.querySearch.dataRef) as RcgResource[] | null | undefined),
        map((data) => {
          if (!data) return [];

          data.forEach((resource) => {
            const resourceProps = {
              view: g,
              resourceId: resource.id,
              data: resource,
            };

            for (const [path, variable] of Object.entries(g.ext_config!.querySearch.dataVariables ?? {})) {
              jsonpath.value(resource, path, parseVariable(variable, resourceProps));
            }
          });

          return data;
        }),
      );
    };

    return localized$.pipe(
      map((l) => ({
        ...g,
        ...l,
        resourceById,
        allResources,
        searchResources,
      })),
    );
  }

  public mapViewData$({ view_resource_groups, ...viewData }: RcgCalendarView): Observable<RcgView> {
    const rg$ = view_resource_groups?.length
      ? combineLatest(view_resource_groups.map((g) => this.mapResourceGroup$(g.resource_group))).pipe(debounceTime(10))
      : of([]);
    const acl = mergeAcls(viewData.permissions?.map((p) => p.acl).filter(isNonNullable) ?? []);

    return rg$.pipe(
      map((rg) => ({
        ...viewData,
        resource_groups: rg,
        acl,
      })),
    );
  }
}
