import { gql } from '@apollo/client/core';

export const updateEventMutation = gql`
  mutation UpdateEventMutation(
    $viewId: String!
    $id: String!
    $rruleset: rruleset!
    $title: String!
    $description: String
    $comment: String
    $isDeleted: Boolean = false
    $setAssignments: jsonb
    $deleteAssignments: jsonb
    $extra: jsonb
    $report: String
  ) {
    sqlFunctionMutation(
      functionName: "calendar.update_event"
      parameters: {
        view_id: $viewId
        id: $id
        rruleset: $rruleset
        title: $title
        description: $description
        comment: $comment
        is_deleted: $isDeleted
        set_assignments: $setAssignments
        delete_assignments: $deleteAssignments
        extra: $extra
        report: $report
      }
    ) {
      __typename
    }
  }
`;
