import { jsPDF } from 'jspdf';

const extraStyles = (height: number, contentHeight: number) => `
html2canvascustomelement,
html2canvascustomelement > .e-table-container,
html2canvascustomelement > .e-table-container > .e-table-wrap,
.e-schedule-table.e-outer-table {
  height: ${height}px !important;
}

html2canvascustomelement > .e-table-container .e-content-wrap,
html2canvascustomelement > .e-table-container .e-time-cells-wrap {
  height: ${contentHeight}px !important;
}

.e-vertical-view .e-time-cells-wrap table td,
.e-vertical-view .e-work-cells {
  height: 40px !important;
}

.e-time-slots.e-hide-childs > * {
  display: block !important;
}

.e-vertical-view .e-header-cells.e-current-day,
.e-vertical-view .e-header-cells.e-current-day * {
  color: inherit !important;
  text-decoration-color: inherit !important;
  text-emphasis-color: inherit !important;
  -webkit-text-stroke-color: inherit !important;
  -webkit-text-fill-color: inherit !important;
}

.e-current-time, .e-current-timeline, .e-previous-timeline {
  display: none !important;
}

.e-appointment:has(.e-appointment-details):has(.week-event-template-container) {
  width: 100% !important;
}

.e-appointment .e-appointment-details .week-event-template-container .header > .___html2canvas___pseudoelement_after {
  width: calc(100% + 26px) !important;
}

.e-appointment:has(.e-appointment-details):has(.week-event-template-container.is-parent-assigned) {
  left: calc(var(--overlap-index) * 16px) !important;
  width: calc(100% * var(--child-count) - var(--overlap-index) * 16px) !important;
  z-index: 2;
}

.e-selected-cell {
  background-color: inherit !important;
}
`;

export async function exportToPdf(ogSchedule: HTMLElement, name: string) {
  const schedule = ogSchedule.cloneNode(true) as HTMLElement;

  const headerHeight = ogSchedule.querySelector('.e-date-header-container')?.scrollHeight ?? 0;
  const contentHeight = ogSchedule.querySelector('.e-schedule-table.e-content-table')!.scrollHeight;

  const width = 1920;
  const height = headerHeight + contentHeight;

  schedule.style.width = `${width}px`;
  schedule.style.height = `${height}px`;

  const doc = new jsPDF({
    format: [width, height + 2],
    unit: 'px',
    orientation: width > height ? 'landscape' : 'portrait',
  });

  const docWidth = doc.internal.pageSize.getWidth();

  const ignoreClasses = [
    'cdk-visually-hidden',
    'cdk-overlay-container',
    'mat-toolbar',
    'mat-sidenav',
    'e-schedule-toolbar-container',
    'e-popup-content',
    'e-dlg-container',
    'e-recurrence-edit-icon',
    'footer',
  ];

  await new Promise((res) =>
    doc.html(schedule, {
      callback: res,
      windowWidth: docWidth,
      width: docWidth,
      autoPaging: false,
      fontFaces: [
        {
          family: 'Roboto',
          src: [
            {
              url: 'https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5Q.ttf',
              format: 'truetype',
            },
          ],
        },
      ],
      html2canvas: {
        windowWidth: width,
        width,
        windowHeight: height,
        height,
        removeContainer: true,
        ignoreElements: (el) => ignoreClasses.some((className) => el.classList?.contains(className)),
        onclone: (doc) => {
          const style = doc.createElement('style');
          style.textContent = extraStyles(height, contentHeight);
          doc.head.appendChild(style);

          //! DEBUG

          /* const h2cc = document.body.querySelector<HTMLIFrameElement>('iframe.html2canvas-container');
          if (!h2cc) return;

          h2cc.style.left = '0px';
          h2cc.style.visibility = 'visible';
          h2cc.style.zIndex = '1000';

          const overlay = h2cc.contentDocument?.body.querySelector<HTMLElement>('.html2pdf__overlay');
          if (!overlay) return;

          overlay.style.pointerEvents = 'none';

          debugger; */
        },
      },
    }),
  );

  await doc.save(`${name}.pdf`, { returnPromise: true });
}
