import { Injectable } from '@angular/core';
import { DirectCall } from '../models/direct-call';

@Injectable({
  providedIn: 'root',
})
export class DirectCallService {
  isInboundCall(call: DirectCall): boolean {
    if (!call.call_type) return false;
    const callType = call.call_type.toLowerCase();
    return callType === 'byotin' || callType === 'byotinucap';
  }

  isOutboundCall(call: DirectCall): boolean {
    if (!call.call_type) return false;
    const callType = call.call_type.toLowerCase();
    return callType === 'byotout' || callType === 'byotoutuserforwarding' || callType === 'byotoutusertransfer';
  }

  getCallStatus(call: DirectCall): string {
    if (call.successful_call === true) {
      return 'Connected';
    }

    if (call.failure_date) {
      if (call.final_sipcode === 487) {
        return 'Cancelled';
      }
      if (call.final_sipcode === 404) {
        return 'User Not Found';
      }
      if (call.final_sipcode === 480) {
        return 'Routing Failed';
      }
      return 'Failed';
    }

    return 'Unknown';
  }

  isSuccessfulCall(call: DirectCall): boolean {
    if (!call) return false;

    return call.successful_call !== undefined && call.successful_call === true && !call.failure_date;
  }

  isInboundMissedCall(call: DirectCall): boolean {
    return (
      this.isInboundCall(call) && !call.successful_call && call.duration === 0 && call.start_date === null && call.failure_date !== null
    );
  }

  isOutboundMissedCall(call: DirectCall): boolean {
    return (
      this.isOutboundCall(call) && !call.successful_call && call.duration === 0 && call.start_date === null && call.failure_date !== null
    );
  }
}
