import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callDuration',
  standalone: true,
})
export class CallDuration implements PipeTransform {
  transform(seconds?: number): string {
    if (!seconds) return '00:00:00';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}

@Pipe({
  name: 'isToday',
  standalone: true,
})
export class IsTodayPipe implements PipeTransform {
  transform(date: string | Date | null | undefined): boolean {
    if (!date) return false;

    const inputDate = new Date(date);
    const today = new Date();

    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  }
}
