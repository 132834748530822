import { gql } from '@apollo/client/core';
import { AuthService, RcgTenant, RcgUser } from '@rcg/auth';
import { FormMode } from '@rcg/core';
import { FormDialogService } from '@rcg/forms';
import { GraphqlClientService } from '@rcg/graphql';
import { MessageService } from '@rcg/standalone';
import { Observable, firstValueFrom } from 'rxjs';
import { DynamicCondition, DynamicVariable, parseVariable } from './dynamic';

export interface RcgCalendarAclActionGqlExecMutation {
  mutation: string;
}

export interface RcgCalendarAclActionGqlExecQuery {
  query: string;
}

export type RcgCalendarAclActionGqlExec = {
  type: 'graphql';
  variables?: Record<string, DynamicVariable | DynamicVariable[]>;
} & (RcgCalendarAclActionGqlExecMutation | RcgCalendarAclActionGqlExecQuery);

export type RcgCalendarAclActionOpenFormExec = {
  type: 'open_form';
  formId?: DynamicVariable | DynamicVariable[];
  formMode?: DynamicVariable | DynamicVariable[];
  dialogIcon?: DynamicVariable | DynamicVariable[];
  dialogTitle?: DynamicVariable | DynamicVariable[];
  prefillData?: Record<string, DynamicVariable | DynamicVariable[]>;
};

export type RcgCalendarAclActionDownloadFileExec = {
  type: 'download_file';
  url?: DynamicVariable | DynamicVariable[];
  method?: DynamicVariable | DynamicVariable[];
  authorize?: boolean;
  body?: Record<string, DynamicVariable | DynamicVariable[]>;
};

export type RcgCalendarAclActionDebugExec = {
  type: 'debug';
  message?: string;
  variables?: Record<string, DynamicVariable | DynamicVariable[]>;
};

export type RcgCalendarAclActionExec =
  | RcgCalendarAclActionGqlExec
  | RcgCalendarAclActionOpenFormExec
  | RcgCalendarAclActionDownloadFileExec
  | RcgCalendarAclActionDebugExec;

export interface RcgCalendarAclAction {
  title: string;
  icon: string;
  closePopup?: boolean;
  exec?: {
    public?: RcgCalendarAclActionExec | RcgCalendarAclActionExec[] | null;
    loggedIn?: RcgCalendarAclActionExec | RcgCalendarAclActionExec[] | null;
    all?: RcgCalendarAclActionExec | RcgCalendarAclActionExec[] | null;
  };
  show?: {
    public?: DynamicCondition;
    loggedIn?: DynamicCondition;
    all?: DynamicCondition;
  };
}

export interface RcgCalendarAcl {
  id?: number;
  ids?: number[];
  add: boolean;
  edit_series: boolean;
  edit_occurrence: boolean;
  resize: boolean;
  drag: boolean;
  delete_series: boolean;
  delete_occurrence: boolean;
  recurrence: boolean;
  actions: RcgCalendarAclAction[] | null;
}

export const defaultRcgCalendarAcl: RcgCalendarAcl = {
  add: false,
  edit_series: false,
  edit_occurrence: false,
  resize: false,
  drag: false,
  delete_series: false,
  delete_occurrence: false,
  recurrence: false,
  actions: [],
};

export function mergeAcls(acls: RcgCalendarAcl[]): RcgCalendarAcl {
  const mergedAcl: RcgCalendarAcl = {
    ids: [],
    add: false,
    edit_series: false,
    edit_occurrence: false,
    resize: false,
    drag: false,
    delete_series: false,
    delete_occurrence: false,
    recurrence: false,
    actions: [],
  };

  for (const acl of acls) {
    if (acl.id) mergedAcl.ids!.push(acl.id);
    if (acl.ids) mergedAcl.ids!.push(...acl.ids);

    for (const key of Object.keys(acl) as (keyof RcgCalendarAcl)[]) {
      if (key !== 'id' && key !== 'ids' && key !== 'actions' && typeof acl[key] === 'boolean') {
        mergedAcl[key] = mergedAcl[key] || acl[key];
        continue;
      }

      if (key === 'actions') {
        if (!acl[key]) continue;

        if (!Array.isArray(acl[key])) {
          console.error('Invalid actions type', acl[key]);
          continue;
        }

        mergedAcl.actions!.push(...acl[key]!);
      }
    }
  }

  return mergedAcl;
}

function _parseVariables(
  variables: Record<string, DynamicVariable | DynamicVariable[]> | undefined,
  data: Record<string, unknown>,
): Record<string, unknown> {
  return Object.fromEntries(Object.entries(variables ?? {}).map(([key, value]) => [key, parseVariable(value, data)]));
}

export async function execAclAction(
  action: RcgCalendarAclAction,
  props: { data: Record<string, unknown>; user: RcgUser | null; tenant: RcgTenant | null },
  gqlClient: GraphqlClientService,
  formDialogService: FormDialogService,
  messageService: MessageService,
  authService: AuthService,
) {
  if (!action.exec) return;

  const exec = action.exec[props.user && props.tenant ? 'loggedIn' : 'public'] ?? action.exec.all;
  if (!exec) return;

  const actions = Array.isArray(exec) ? exec : [exec];

  for (const action of actions) {
    switch (action.type) {
      case 'graphql': {
        let fn: (variables: Record<string, unknown>) => Observable<unknown>;

        if ('mutation' in action) {
          fn = (variables) => gqlClient.mutate({ mutation: gql(action.mutation), variables });
        } else if ('query' in action) {
          fn = (variables) => gqlClient.query({ query: gql(action.query), variables });
        } else {
          console.error('Unknown graphql action type', action);
          continue;
        }

        await firstValueFrom(fn(_parseVariables(action.variables, props)));

        continue;
      }

      case 'open_form': {
        formDialogService.openForm({
          formId: parseVariable(action.formId, props) as string,
          formMode: parseVariable(action.formMode, props) as FormMode,
          dialogIcon: parseVariable(action.dialogIcon, props) as string,
          dialogTitle: parseVariable(action.dialogTitle, props) as string,
          prefillData: _parseVariables(action.prefillData, props),
        });
        continue;
      }
      case 'download_file': {
        let downloadUrl: string | undefined;

        try {
          const url = parseVariable(action.url, props) as string;
          const method = (parseVariable(action.method, props) as string) || 'GET';
          const body = action.body ? JSON.stringify(_parseVariables(action.body, props)) : undefined;

          const response = await fetch(url, {
            method,
            body,
            headers: {
              ...(action.authorize ? { Authorization: `Bearer ${await authService.getRawAuthToken()}` } : undefined),
              ...(action.body ? { 'Content-Type': 'application/json' } : undefined),
            },
          });

          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

          const blob = await response.blob();
          downloadUrl = window.URL.createObjectURL(blob);

          const downloadAnchor = document.createElement('a');
          downloadAnchor.style.display = 'none';
          downloadAnchor.href = downloadUrl;
          const contentDisposition = response.headers.get('Content-Disposition');
          const filenameMatch = contentDisposition ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) : null;
          downloadAnchor.download = filenameMatch ? filenameMatch[1] : 'download';

          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
          document.body.removeChild(downloadAnchor);
        } catch (error) {
          console.error('Download file error:', error);
          messageService.showErrorSnackbar('Download file error', error, 10);
        }

        if (downloadUrl) window.URL.revokeObjectURL(downloadUrl);

        continue;
      }
      case 'debug':
        console.debug((action as RcgCalendarAclActionDebugExec).message ?? 'Action debug', _parseVariables(action.variables, props));
        continue;
      default:
        console.error('Unknown action type', action);
        continue;
    }
  }
}
