@if (directCall(); as call) {
<rcg-card style="height: 120px; background-color: white">
  <div style="display: flex; align-items: center" title>
    @if (callService.isInboundCall(call)) {
    <mat-icon
      [matTooltip]="'incoming_call' | intl"
      class="phone-call"
      [class.success]="callService.isSuccessfulCall(call)"
      [class.failure]="!callService.isSuccessfulCall(call)"
    >
      phone_callback
    </mat-icon>
    } @else if (callService.isOutboundCall(call)) {
    <mat-icon
      [matTooltip]="'outgoing_call' | intl"
      class="phone-call"
      [class.success]="callService.isSuccessfulCall(call)"
      [class.failure]="!callService.isSuccessfulCall(call)"
    >
      phone_forwarded
    </mat-icon>
    } @else {
    <mat-icon [matTooltip]="'unknown_direction_call' | intl" class="phone-call"> phone </mat-icon>
    }

    <span class="call-type" style="margin-left: 8px">
      @if (callService.isInboundCall(call)) {
      {{ 'incoming_call' | intl }}
      } @else if (callService.isOutboundCall(call)) {
      {{ 'outgoing_call' | intl }}
      } @else {
      {{ 'unknown_call' | intl }}
      }
    </span>
  </div>

  <div titleAction>
    <div style="display: flex; justify-content: space-between; align-items: center">
      @if (callService.isSuccessfulCall(call)) { @if (call.start_date) { @if (call.start_date | isToday) {
      {{ call.start_date | date : 'shortTime' }}
      } @else {
      {{ call.start_date | date : 'd. MM. yy, HH:mm' }}
      } } } @else { @if (call.failure_date) { @if (call.failure_date | isToday) {
      {{ call.failure_date | date : 'shortTime' }}
      } @else {
      {{ call.failure_date | date : 'd. MM. yy, HH:mm' }}
      } } }
    </div>
  </div>

  <div class="big-font" subtitle>{{ call.agent_name ?? call.user_display_name }}</div>

  <div subtitleAction>
    @if (callService.isInboundCall(call)) {
    {{ call.callee_number ?? '' }}
    } @else {
    {{ call.caller_number ?? '' }}
    }
  </div>

  <div content>
    <!-- '\u00A0' -->
    <div class="big-font">{{ call.contact_name ?? '-' }}</div>
    <div>{{ call.duration | callDuration }}</div>
  </div>

  <div contentAction>
    @if (callService.isInboundCall(call)) {
    {{ call.caller_number ?? '' }}
    } @else {
    {{ call.callee_number ?? '' }}
    } @if (callService.isInboundMissedCall(call)) {
    <div class="row-end">
      <mat-icon class="small-icon" [matTooltip]="'missed_incoming_call' | intl" color="accent">call_missed</mat-icon>
    </div>
    } @else if (callService.isOutboundMissedCall(call)) {
    <div class="row-end">
      <mat-icon class="small-icon" [matTooltip]="'missed_outgoing_call' | intl" color="accent">call_missed_outgoing</mat-icon>
    </div>
    }
  </div>
</rcg-card>

<mat-card class="detail-card">
  <div class="detail-container">
    <div class="detail-row">
      <span class="detail-label">Koda:</span>
      <span>{{ call.final_sipcode }} - {{ call.final_sipcode_phrase || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-label">Začetek:</span>
      <span>{{ call.start_date ? (call.start_date | date : 'medium') : '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-label">Konec:</span>
      <span>{{ call.end_date ? (call.end_date | date : 'medium') : '-' }}</span>
    </div>
  </div>
</mat-card>
}
