import jsonpath from 'jsonpath';

export interface DynamicVariablePath {
  type: 'path';
  path: string;
}

export interface DynamicVariableLiteral {
  type: 'literal';
  value: unknown;
}

export type DynamicVariable = DynamicVariablePath | DynamicVariableLiteral;

export function parseVariable(value: unknown, data: Record<string, unknown>): unknown {
  if (!value) return value;

  if (Array.isArray(value)) {
    return value.map((v) => parseVariable(v, data)).join('');
  }

  if (typeof value !== 'object') {
    console.error('Unknown variable type', value);
    return value;
  }

  if ('path' in value) {
    if (typeof value.path !== 'string') {
      console.error('Invalid path type', value.path);
      return value;
    }

    return jsonpath.value(data, value.path as string);
  }

  if ('literal' in value) {
    return value.literal;
  }

  console.error('Unknown variable object type', value);
  return value;
}
