import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { RcgCardComponent } from '../../card/card.component';
import { DirectCall } from '../models/direct-call';
import { CallDuration, IsTodayPipe } from '../pipes';
import { DirectCallService } from '../services/direct-call.service';

@Component({
  selector: 'rcg-direct-call-detail',
  standalone: true,
  templateUrl: './direct-call-detail.component.html',
  styleUrls: ['./direct-call-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    IntlModule,
    MatTooltipModule,
    IsTodayPipe,
    CallDuration,
    RcgCardComponent,
    MatToolbarModule,
    MatCardModule,
  ],
})
export class DirectCallDetailComponent {
  directCall = input.required<DirectCall>();
  public callService = inject(DirectCallService);
}
